<template>
  <div>
    <div id="mapid"></div>
  </div>
</template>

<script>
import L from 'leaflet'
import {mapActions, mapGetters} from "vuex";

let yx = L.latLng

let xy = function (x, y) {
  if (L.Util.isArray(x)) { // When doing xy([x, y]);
    return yx(x[1], x[0])
  }
  return yx(y, x) // When doing xy(x, y);
}

let salonIcon = new L.Icon({
  iconUrl: require('@/assets/marker-icon/svg-pin.svg'),
  // iconUrl: require('@/assets/marker-logo.svg'),
  shadowUrl: require('@/assets/marker-icon/marker-shadow.png'),
  iconSize: [80, 80],
  iconAnchor: [40, 85],
  //iconAnchor: [15, 52],
  shadowSize: [80, 80],
  shadowAnchor: [25, 80]
})

export default {
  name: 'Leaflet',
  data () {
    return {
      map: null, // Objet map leafLet
      rectIlots: [],
      markerIlots: [],
      modal: null
    }
  },
  methods: {
    ...mapActions(['setCarteMetier', 'setCarteIlot', 'resetCarte']),
    getDetailIlot (idIlot) {
      return this.getIlot(idIlot)
    },
    getDetailMetier (idMetier) {
      return this.getMetier(idMetier)
    },
    removeMap () {
      console.log('<<<< **** CARTE SUPPRIMEE **** >>>>')
      this.map.remove()
    },
    createMap () {
      console.log('*********** CREATE MAP *********** ', this.map)
      return new Promise(resolve => {
        // Attends (1-2sec) que la vue  soit montée
        // pour en connaitre la hauteur réelle du composant
        setTimeout(() => {
          // Dimensions de la carte
          let bounds = [xy(0, 0), xy(2000, 2000)]
          let boundsMax = [xy(0, 0), xy(2300, 2300)]

          /************************
           * Création de la carte *
           * *********************/
          this.map = L.map('mapid', {
            crs: L.CRS.Simple,
            minZoom: -2,
            maxZoom: -1,
            attributionControl: false,
            zoomControl: false,
            maxBounds: boundsMax
          })
          // Image utilisée comme arrière plan de la map
          L.imageOverlay(require('@/assets/plan-papier-2022.png'), bounds).addTo(this.map)

          // Centrer la carte et zoom par défaut
          this.centerMap()

          // Si on clic sur la map
          this.map.on('click', this.onMapClick)

          // Création des zones (rectangles) et marker de tous les îlots
          this.createRectAndMarker()
          /************** CREATION RECTANGLE SUR MAP ***************
          const that = this
          this.map.on('click', function (e) {
            let coords = '[' + e.latlng.lat + ', ' + e.latlng.lng + ']'
            // alert('[' + e.latlng.lat + ', ' + e.latlng.lng + ']')
            // console.log(this.copy)
            if (that.copyTmp === '') {
              that.copyTmp = coords
              that.copy = coords
            } else {
              that.copy = '[' + that.copyTmp + ', ' + coords + ']'
              that.copyTmp = ''
              navigator.clipboard.writeText(that.copy)
              alert('copié !')
            }
          })
          /*****************************************************/

          // Fin de la promesse
          resolve('resolved')
        }, 500)
      })
    },
    onMapClick (e) {
      if (e.originalEvent.originalTarget.id === 'mapid') {
        this.reset()
      }
    },
    centerMap (top) {
      // Centrer la carte et zoom par défaut
      if (top) {
        this.map.setView(xy(1150, 500), -2)
      } else {
        this.map.setView(xy(1150, 1000), -2)
      }
    },
    showMetier (idMetier) {
      // Récupère les coordonnées de tous les ilôts ou le métier est présenté
      let ilots = this.getDetailMetier(idMetier).ilots

      // Contiendra les coordonées des îlots a afficher
      let coordIlots = []

      // Retire tous les marker d'îlots actuellement présent sur la carte
      this.removeMarkerIlots()

      // Récupère les coordonnées des îlots et ajoute les marqueurs à la carte
      for (let ilot of ilots) {
        if (ilot.lat_lng) {
          coordIlots.push(this.markerIlots[ilot.id].getLatLng())
          this.markerIlots[ilot.id].addTo(this.map)
          // TODO faire bouncer les marker
          // TODO ajouter infobulle avec nom métier recherché et l'afficher
        }
      }

      // Centre la carte sur les îlots présentant le métier
      this.map.flyToBounds(coordIlots, { maxZoom: -2 })

      // m.openPopup()
      // this.map.setView(latLng, 1, { animation: true })
      // this.map.panTo(this.getDetailMetier(idMetier).latlng)
    },
    showIlot (idIlot) {
      // Retire tous les marker d'îlots actuellement présent sur la carte
      this.removeMarkerIlots()
      let ilot = this.getDetailIlot(idIlot)
      let m = this.markerIlots[ilot.id]

      m.addTo(this.map)

      let bounds = new L.LatLngBounds([m.getLatLng()])

      this.map.fitBounds(bounds, {
        paddingTopLeft: [0, -200]
      })
    },
    rectIlotsOnClick (e, id) {
      // this.$emit('setIlots', id)
      this.setCarteIlot(id)
    },
    createRectAndMarker () {
      let that = this

      for (let ilot of this.ilots) {
        // Si îlot n'a pas de coordonnées on passe au suivant
        if (!ilot.lat_lng) { continue }
        /*
        // Iinitialise le texte de l'îlot avec son nom ou la liste des métiers
        let txtIlot = ilot.nom ? '<h2>' + ilot.nom + '</h2>' : ''

        // Liste des métiers de l'îlot
        if (ilot.metiers) {
          txtIlot += '<ul>'
          for (let metier of ilot.metiers) {
            if (parseInt(metier.titre) !== 0) {
              txtIlot += '<li>' + metier.nom + '</li>'
            }
          }
          txtIlot += '</ul>'
        } */

        // Si il y plusieurs coordonnées => c'est un rectangle
        if (ilot.lat_lng[0][0]) {
          // Création du marker d'îlot
          this.markerIlots[ilot.id] = L.marker(this.rectCenter(ilot.lat_lng), { icon: salonIcon })

          // Création du rectangle d'îlot
          this.rectIlots[ilot.id] = L.rectangle(ilot.lat_lng, {
            opacity: 0,
            fillOpacity: 0
          })
            .addTo(that.map)
            .on('click', function (e) { that.rectIlotsOnClick(e, ilot.id) })
            // .bindPopup(txtIlot)
        } else {
          // Création du marker d'îlot
          this.markerIlots[ilot.id] = L.marker(ilot.lat_lng)
          // this.markerIlots[metier.id] = L.marker(metier.lat_lng, { icon: redIcon })
          this.rectIlots[ilot.id] = L.circleMarker(ilot.lat_lng, {
            radius: 15,
            weight: 2,
            // color: '#e30613',
            color: '#fff',
            opacity: 0,
            fill: true,
            fillOpacity: 0,
            fillColor: ilot.secteur.couleur
          })
            .addTo(this.map)
            // .on('click', this.markerOnClick)
            // .bindPopup(txtIlot)
        }
      }
    },
    removeMarkerIlots () {
      for (let marker of this.markerIlots) {
        this.removeMarker(marker)
      }
    },
    removeMarker (marker) {
      if (this.map.hasLayer(marker)) {
        this.map.removeLayer(marker)
      }
    },
    rectCenter (coords) {
      // xCenter = (xa + xb) / 2
      // yCenter = (ya + yb) / 2
      return [(coords[0][0] + coords[1][0]) / 2, (coords[0][1] + coords[1][1]) / 2]
    },
    reset (center = true) {
      this.resetCarte()
      this.removeMarkerIlots()
      if (center) {
        this.centerMap()
      }
    }
  },
  computed: {
    ...mapGetters(['ilots', 'getIlot', 'getMetier', 'carteMetierId', 'carteIlotId']),
  },
  watch: {
    carteMetierId (id) {
      console.log('-- carteMetierId a changé:', id)
      if(id) {
        this.showMetier(this.carteMetierId)
      }
    },
    carteIlotId (id) {
      console.log('-- carteIlotId a changé:', id)
      if(id) {
        this.showIlot(this.carteIlotId)
      }
    }
  },
  mounted() {
    console.log('-- LEAFLET MONTEE ---', this.map)

    let that = this

    this.$nextTick(async function () {
      console.log('-- LEAFLET $nextTick ---')

      console.log('-- createMap start ---')
      await that.createMap()
      console.log('-- createMap end ---')

      if (that.carteMetierId) {
        that.showMetier(that.carteMetierId)
      } else if (that.carteIlotId) {
        that.showIlot(that.carteIlotId)
      }
    })
  },
  unmounted() {
    this.removeMap()
    console.log('XXXXXX DESTRUCTION LEAFLET XXXXXX')
  },
}
</script>

<style scoped lang="sass">
  #mapid
    height: calc(100vh - 170px)
    background: white
    /* cursor: crosshair; */

  #mapid .leaflet-popup h2
    font-size: 1rem
</style>
