<template>
  <ion-list>
    <ion-list-header v-if="titre" v-html="titre" />
    <ion-item
      lines="full"
      button
      @click="$router.push({name: 'ficheMetier', params: { id: metier.id } })"
      v-for="metier in metiersAZ"
      :key="metier.id"
    >
      <ion-label class="ion-text-wrap">{{ metier.nom }}</ion-label>
    </ion-item>
  </ion-list>
</template>

<script>
import { IonList, IonListHeader, IonItem, IonLabel } from '@ionic/vue'

export default {
  name: 'ListeMetiers',
  components: {
    IonList, IonListHeader, IonItem, IonLabel
  },
  props: {
    titre: { required: false },
    metiers: { required: true }
  },
  computed: {
    metiersAZ() {
      let copieMetiers = this.metiers
      // Tri A-Z des métiers par nom
      copieMetiers.sort((a, b) => a.nom.localeCompare(b.nom, 'fr'))

      return copieMetiers.filter(metier => metier.ilots.length > 0)
      // TODO Ne doit retourner que les métiers qui sont dans un secteur ou un ilôt
      // TODO Idéalement le tri devrait être fait par la BD ou le magasin
    }
  }
}
</script>
