<template>
  <div class="modal">
    <ion-card>
      <ion-item
          v-if="metier" color="primary"
      >
        <ion-icon :icon="close"
                  slot="end"
                  @click="resetCarte"
        ></ion-icon>
        <ion-label>{{metier.nom}}</ion-label>
      </ion-item>
      <!-- Ilot -->

        <ion-item
            lines="full"
            v-if="ilot"
            :style="{ color: ilot.secteur.couleur }"
            >
          <ion-icon :icon="close"
                    slot="end"
                    @click="resetCarte"
          ></ion-icon>
          <ion-label
          ><span v-if="ilot.numero > 0"> {{ilot.numero}} - </span>
            {{ilot.nom}}</ion-label>
        </ion-item>

        <ListeMetiers class="list"
                      v-if="ilot && ilot.metiers"
                      :metiers="ilot.metiers"
                      :afficher-stands="true"
        />
        <ion-card-content
          v-if="ilot && ilot.metiers[0]
        && ilot.metiers.length === 1
        && ilot.metiers[0].titre === '0'
        && ilot.metiers[0].description !== '0'"
        >
          {{ ilot.metiers[0].description }}
        </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
import ListeMetiers from './ListeMetiers'
import {
  IonCard,
  IonCardContent,
  IonIcon,
  IonItem,
  IonLabel
} from "@ionic/vue";
import {
  close,
} from "ionicons/icons";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'MapModal',
  components: {
    ListeMetiers,
    IonIcon,
    IonCardContent,
    IonCard,
    IonItem,
    IonLabel
  },
  setup() {
    return {
      close
    }
  },
  data () {
    return {
      open: false
    }
  },
  methods: {
    ...mapActions(['resetCarte']),
    getDetailIlot (idIlot) {
      return this.getIlot(idIlot)
    },
    getDetailMetier (idMetier) {
      return this.getMetier(idMetier)
    }
  },
  computed: {
    ...mapGetters(['ilots', 'getIlot', 'getMetier', 'carteMetierId', 'carteIlotId']),
    /**
     * Retourne le métier actif ou null
     * @returns {*} - Objet Métier
     */
    metier () {
      return this.carteMetierId ? this.getDetailMetier(this.carteMetierId) : null
    },
    /**
     * Retourne le ilot actif ou null
     * @returns {*} - Objet Métier
     */
    ilot () {
      return this.carteIlotId ? this.getDetailIlot(this.carteIlotId) : null
    }
  },
}
</script>

<style scoped>


  .modal {
    position: absolute;
    bottom:5px;
    left:0;
    width: 100%;
    z-index: 300000;
  }

  .list {
    max-height: 40vh;
    overflow: scroll;
  }

  .modal ion-item {
    font-weight: bold;
  }
</style>
