<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$router.back()">
            <ion-icon :icon="chevronBackSharp"></ion-icon> Retour
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <leaflet ref="map" />
      <MapModal />
    </ion-content>
  </ion-page>
</template>

<script>
import Leaflet from '../components/Leaflet'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader, IonIcon,
  IonPage,
  IonToolbar
} from "@ionic/vue";

import { chevronBackSharp } from "ionicons/icons";
import MapModal from "@/components/MapModal";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'PageCarte',
  components: {
    MapModal,
    Leaflet,
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonIcon,
    IonButtons,
    IonButton
  },
  setup() {
    return { chevronBackSharp };
  },
  methods: {
    ...mapActions(['resetCarte']),
  },
  computed: {
    ...mapGetters(['carteMetierId', 'carteIlotId']),
  }
}
</script>

<style scoped>

</style>
